import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./Footer.css";
import Logo from "../../Assests/images/logo.png";
import PdfInvest from "../../Assests/pdf/investor-charter.pdf";
import PdfInvest1 from "../../Assests/pdf/INVESTOR_CHARTER_COMPLAINT.pdf";
import {AiFillCloseCircle} from 'react-icons/ai';
function Footer() {

  const [show , setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openPDF = () => {
    const pdfWindow = window.open("Investor Charter");
    const title = "Investor Charter";
    const URI = "Investor Charter";
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${PdfInvest} type="application/pdf">
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };
  const openPDFComplaint = () => {
    const pdfWindow = window.open("Investor Complaint");
    const title = "Investor Complaint";
    const URI = "Investor Complaint";
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${PdfInvest1} type="application/pdf">
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };
  return (
    <>
      <section>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div className="attention-investor">
                <h5>
                  <strong>Attention Investor</strong>
                  <marquee>
                  1. Stock Brokers can accept securities as margin from clients only by way of pledge in the depository system w.e.f. September 01, 2020. 2. Update your email id and mobile number with your stock broker / depository participant and receive OTP directly from depository on your email id and/or mobile number to create pledge. 3. Check your securities / MF / bonds in the consolidated account statement issued by NSDL/CDSL every month........... Issued in the interest of Investors"
                  </marquee>
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <div className="footer-logo">
                <img className="img-fluid" src={Logo} alt="footer-logo" />
              </div>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="footer-reach-list">
                <ul>
                  <li>
                    <p>Email id:</p>
                    <h6>Info@bhaijee.com</h6>
                  </li>
                  <li>
                    <p>Phone Number:</p>
                    <h6>+91-11-47475555</h6>
                  </li>
                  <li>
                    <p>Fax:</p>
                    <h6>+91-11-47475555</h6>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div className="register-number">
                <h4>BHAIJEE PORTFOLIO PRIVATE LIMITED</h4>
                <p>CIN:  U74899DL1995PTC401367</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <div className="address">
                <h4>Address :</h4>
                <p>
                  <strong>
                    03rd Floor, Building No 14, Central Market, Ashok Vihar,
                    Delhi-110052
                  </strong>
                </p>
              </div>
            </Col>
            <Col xs={12} md={8} lg={8}>
              <div className="list-link-footer">
                <ul>
                  <li>
                    <a href="/aboutus">About Us</a>
                  </li>
                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                  <li>
                    <a href="/productservices">Product & Services</a>
                  </li>
                  <li>
                    <a href="/downloads">Downloads</a>
                  </li>
                  <li>
                    <a href="https://closure.meon.co.in/cloud_closure/closure/Bhaijee" target="_blank"> Account Close </a>
                  </li>
                </ul>
                <ul>
                  <li><a href="/disclaimer">Disclaimer</a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                  <li><a href="/pmla">PMLA & RMS Policy</a></li>
                  <li><a href="/InactiveClient">Inactive Client</a></li>
                  <li><a href="https://evoting.cdslindia.com/Evoting/EvotingLogin" target="_blank">E-Voting</a></li>
                  <li><a href="/BankAccount">Client Bank Account</a></li>
                </ul>
                <ul>
                  <li><a href="https://webbkp.bhaijee.com:8443/capexweb/capexweb/" target="_blank">Back Office</a></li>
                  <li><a onClick={handleShow}>SEBI-SCORES</a></li>
                  <li><a href="/advisory">Advisory for Investors</a></li>
                  <li> <a href="/investorCorner"> Investor Corner </a></li>
                  {/* <li><a target="_blank" onClick={openPDF}>Investor Charter</a></li>
                  <li><a target="_blank" onClick={openPDFComplaint}>Investor Complaint</a></li> */}
                  <li><a href="https://www.sebi.gov.in/">SEBI</a></li>
                  <li><a href="/UsefulLink">Useful Link</a></li>
                </ul>
                <ul>
                  <li><a href="https://www.nseindia.com/">NSE</a></li>
                  <li><a href="https://www.bseindia.com/">BSE</a></li>
                  <li><a href="https://www.cdslindia.com/">CDSL</a></li>
                  <li><a href="https://nsdl.co.in/">NSDL</a></li>
                  <li><a href="https://www.mcxindia.com/">MCX</a></li>
                  <li><a href="https://www.ncdex.com/">NCDEX</a></li>
                  
                </ul>
              </div>
              <div>
              <Modal show={show} onHide={handleClose} className="complaint-modal">
                <Modal.Body >
                  <div onClick={handleClose} className="text-right">
                    <AiFillCloseCircle></AiFillCloseCircle>
                  </div>
                  <div>
                    <h3>Filing Complaints on SCORES- Easy & quick</h3>
                    <ul>
                      <li>a. Register on SCORES portal</li>
                      <li>
                        b. Mandatory details for filing complaints on SCORES:
                        <ul>
                          <li>i. Name,PAN,Address,Mobile Number, Email ID</li>
                        </ul>
                      </li>
                      <li>
                      c. Benefits:
                        <ul>
                          <li>i. Effective Communication</li>
                          <li> ii. Speedy redressal of the grievances</li>
                        </ul>
                      </li>
                    </ul>
                  </div>  
                </Modal.Body>
              </Modal>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div className="complaints-block">
                <p>
                  In case of any grievance/complaint against the Stock Broker
                  /Depository Participant:
                </p>
                <p>
                  Please contact: <strong>Mr. Satish Kumar Gupta</strong>
                  (Director) Email-id: <strong>contact@bhaijee.com</strong>
                  Phone: <strong>+91-11-47475555</strong>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div className="sebi-reg">
                <p>SEBI Regn No: INZ000089933, CDSL-IN-DP- 464-2020</p>
                <p>
                  NSE Membership No-12999 BSE Clg No: 6125, MCX Membership
                  No-29870, NCDEX Membership No-00441 & CDSL DP Id- 12069400, MSEI Membership No. 25000 
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="copyright-block">
        <Container>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <p>
                Copyright &copy; 2022 Bhaijee Portfolio Private Limited. All
                rights reserved.
              </p>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <p>
                Designed & Developed by <a href="https://www.cmots.com/">CMOTS Infotech</a> (ISO
                9001:2015 certified)
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Footer;
